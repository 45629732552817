<template>
  <span>
    <v-card class="mb-0 mt-n2 parametros-v-card">
      <v-row justify="start" class="mx-auto mt-0">
        <v-col class="pb-0" cols="12" sm="6" md="4">
          <v-select
            class="ml-8"
            :items="regionaisList"
            :value="regionalSelecionada"
            @change="setRegionalSelecionada"
            label="Regional selecionado"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-tabs v-model="_subTab" grow color="secundary">
      <v-tab>
        Reclamação
      </v-tab>
      <v-tab>
        Qualidade dos Serviços
      </v-tab>
      <v-tab>
        Técnicos
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="_subTab"
      style="background-color: transparent !important;"
    >
      <v-tab-item>
        <layout-visao-global
          :indicadores="[
            'qrt',
            'prp',
            'QTD Reclamações Totais',
            'QTD Reclamações Procedentes',
          ]"
          :dadosMensais="dadosMensais"
          :dadosTotais="dadosTotais"
          :categories="categories"
          :traducaoIndicadores="traducaoIndicadores"
        />
      </v-tab-item>

      <v-tab-item>
        <layout-visao-global
          :indicadores="[
            'psfpg',
            'psfpt-crp',
            'psfpt-cri',
            'psfpt-rmp',
            'psfpt-rmc',
            'Total Compensação',
          ]"
          :dadosMensais="dadosMensais"
          :dadosTotais="dadosTotais"
          :categories="categories"
          :traducaoIndicadores="traducaoIndicadores"
        />
      </v-tab-item>

      <v-tab-item>
        <layout-visao-global
          :indicadores="['plna', 'drpe', 'drce']"
          :dadosMensais="dadosMensais"
          :dadosTotais="dadosTotais"
          :categories="categories"
          :traducaoIndicadores="traducaoIndicadores"
        />
      </v-tab-item>
    </v-tabs-items>
  </span>
</template>

<script>
  import DashboardNivelTensaoService from "@/services/DashboardNivelTensaoService.js";
  import MunicipiosService from "@/services/MunicipiosService";
  import { mapState, mapActions } from "vuex";

  export default {
    name: "DashboardNivelTensaoVisaoGlobal",
    props: {
      competenciaDe: {
        type: String,
        required: true,
      },
      competenciaAte: {
        type: String,
        required: true,
      },
      inspecoesSelecionadas: {
        type: Array,
        required: true,
      },
      medicoesSelecionadas: {
        type: Array,
        required: true,
      },
      origemMedicoesSelecionadas: {
        type: Array,
        required: true,
      },
      tensaoReferenciaSelecionadas: {
        type: Array,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
      activatedTab: {
        type: Number,
        required: true,
      },
      subTab: {
        type: Number,
        required: true,
      },
      traducaoIndicadores: {
        type: Object,
        default: () => {},
      },
    },
    components: {
      LayoutVisaoGlobal: () =>
        import("@/components/dashboardNivelTensao/LayoutVisaoGlobal.vue"),
    },
    data: () => ({
      dadosTotais: {},
      dadosMensais: {},
      regionais: [],
    }),
    computed: {
      ...mapState('parametrosDashboardNivelTensao', [
        'regionalSelecionada',
      ]),
      formattedCompetencia() {
        const [year, month] = this.competencia.split("-");

        return `${month}/${year}`;
      },
      _subTab: {
        get() {
          return this.subTab;
        },
        set(newValue) {
          this.$emit("update:subTab", newValue);
        },
      },
      regionaisList() {
        return this.regionais.map((el) => el.nome);
      },
      regionalSelecionadaId() {
        let regional = this.regionais.find(
          (element) => element.nome == this.regionalSelecionada
        );
        return regional == undefined ? 0 : regional.id;
      },
    },
    mounted() {
      this.getRegionais();
      this.getDados();
    },
    methods: {
      ...mapActions('parametrosDashboardNivelTensao', [
        'setRegionalSelecionada',
      ]),
      getDados() {
        // Apenas atualiza os dados se for a visão selecionada
        if (this.activatedTab !== 0) return;

        this.$emit("updateLoadingDados", true);

        Promise.all([this.getDadosTotais(), this.getDadosMensais()])
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro recuperar dados.", "", {
              position: "topRight",
            });
          })
          .finally(() => this.$emit("updateLoadingDados", false));
      },
      getDadosTotais() {
        if (this._subTab === 0) return this.getDadosTotaisReclamacao();
        if (this._subTab === 1) return this.getDadosTotaisServicos();
        if (this._subTab === 2) return this.getDadosTotaisTecnicos();
      },
      getDadosMensais() {
        if (this._subTab === 0) return this.getDadosMensaisReclamacao();
        if (this._subTab === 1) return this.getDadosMensaisServicos();
        if (this._subTab === 2) return this.getDadosMensaisTecnicos();
      },
      getDadosTotaisReclamacao() {
        return DashboardNivelTensaoService.getDadosIndicadoresReclamacoes(
          this.competenciaDe,
          this.competenciaAte,
          this.inspecoesSelecionadas.join(","),
          this.regionalSelecionadaId
        )
          .then((res) => {
            this.dadosTotais = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosMensaisReclamacao() {
        return DashboardNivelTensaoService.getDadosIndicadoresReclamacoesMensais(
          this.competenciaDe,
          this.competenciaAte,
          this.inspecoesSelecionadas.join(","),
          this.regionalSelecionadaId
        )
          .then((res) => {
            this.dadosMensais = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosTotaisTecnicos() {
        return DashboardNivelTensaoService.getDadosIndicadoresTecnicos(
          this.competenciaDe,
          this.competenciaAte,
          this.medicoesSelecionadas.join(","),
          this.origemMedicoesSelecionadas.join(","),
          this.tensaoReferenciaSelecionadas.join(","),
          this.regionalSelecionadaId
        )
          .then((res) => {
            this.dadosTotais = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosMensaisTecnicos() {
        return DashboardNivelTensaoService.getDadosIndicadoresTecnicosMensais(
          this.competenciaDe,
          this.competenciaAte,
          this.medicoesSelecionadas.join(","),
          this.origemMedicoesSelecionadas.join(","),
          this.tensaoReferenciaSelecionadas.join(","),
          this.regionalSelecionadaId
        )
          .then((res) => {
            this.dadosMensais = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosTotaisServicos() {
        return DashboardNivelTensaoService.getDadosIndicadoresServicos(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId
        )
          .then((res) => {
            this.dadosTotais = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getDadosMensaisServicos() {
        return DashboardNivelTensaoService.getDadosIndicadoresServicosMensais(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId
        )
          .then((res) => {
            this.dadosMensais = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getRegionais() {
        return MunicipiosService.getRegionais()
          .then((res) => {
            this.regionais = res;
            if (this.regionalSelecionada == null) {
              this.setRegionalSelecionada(res.find(
                (el) => el.nome.toLowerCase() == "corporativa"
              ).nome);
            }
          })
          .catch((err) => {
            throw err;
          });
      },
    },
    watch: {
      activatedTab() {
        this.getDados();
      },
      _subTab() {
        this.getDados();
      },
      competenciaDe() {
        this.getDados();
      },
      competenciaAte() {
        this.getDados();
      },
      regionalSelecionada() {
        this.getDados();
      },
    },
  };
</script>

<style>
  .v-card-title-icon {
    position: relative;
    top: -40px;
    transition: 0.5s ease;
  }
</style>
