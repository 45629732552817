import APIService from "./APIService";

export default {
  getDadosIndicadoresReclamacoes(
    competenciaDe,
    competenciaAte,
    inspecoesSelecionadas,
    regional
  ) {
    const inspecoes = encodeURI(inspecoesSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-reclamacoes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&inspecoes=${inspecoes}&regional=${regional}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosIndicadoresReclamacoesMensais(
    competenciaDe,
    competenciaAte,
    inspecoesSelecionadas,
    regional
  ) {
    const inspecoes = encodeURI(inspecoesSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-reclamacoes-mensais?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&inspecoes=${inspecoes}&regional=${regional}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getIndicadoresReclamacaoMensaisTodasEmpresas(
    competenciaDe,
    competenciaAte,
    inspecoesSelecionadas,
  ) {
    const inspecoes = encodeURI(inspecoesSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-reclamacoes-mensais-todas-empresas?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&inspecoes=${inspecoes}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  getDadosIndicadoresTecnicos(
    competenciaDe,
    competenciaAte,
    medicoesSelecionadas,
    origemMedicoesSelecionadas,
    tensaoReferenciaSelecionadas,
    regional
  ) {
    const medicoes = encodeURI(medicoesSelecionadas);
    const origemMedicoes = encodeURI(origemMedicoesSelecionadas);
    const tensaoReferencia = encodeURI(tensaoReferenciaSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-tecnicos?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&medicoes=${medicoes}&origem_medicoes=${origemMedicoes}&tensao_referencia=${tensaoReferencia}&regional=${regional}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosIndicadoresTecnicosMensais(
    competenciaDe,
    competenciaAte,
    medicoesSelecionadas,
    origemMedicoesSelecionadas,
    tensaoReferenciaSelecionadas,
    regional
  ) {
    const medicoes = encodeURI(medicoesSelecionadas);
    const origemMedicoes = encodeURI(origemMedicoesSelecionadas);
    const tensaoReferencia = encodeURI(tensaoReferenciaSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-tecnicos-mensais?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&medicoes=${medicoes}&origem_medicoes=${origemMedicoes}&tensao_referencia=${tensaoReferencia}&regional=${regional}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getIndicadoresTecnicosMensaisTodasEmpresas(
    competenciaDe,
    competenciaAte,
    medicoesSelecionadas,
    origemMedicoesSelecionadas,
    tensaoReferenciaSelecionadas
  ) {
    const medicoes = encodeURI(medicoesSelecionadas);
    const origemMedicoes = encodeURI(origemMedicoesSelecionadas);
    const tensaoReferencia = encodeURI(tensaoReferenciaSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-tecnicos-mensais-todas-empresas?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&medicoes=${medicoes}&origem_medicoes=${origemMedicoes}&tensao_referencia=${tensaoReferencia}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  getDadosIndicadoresServicos(
    competenciaDe,
    competenciaAte,
    regional
  ) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-servicos?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional=${regional}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosIndicadoresServicosMensais(
    competenciaDe,
    competenciaAte,
    regional
  ) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-servicos-mensais?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional=${regional}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getIndicadoresServicosMensaisTodasEmpresas(
    competenciaDe,
    competenciaAte
  ) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-servicos-mensais-todas-empresas?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  getDadosIndicadoresPorMunicipio(
    competenciaDe,
    competenciaAte,
    regionalId,
    inspecoesSelecionadas,
    medicoesSelecionadas,
    origemMedicoesSelecionadas,
    tensaoReferenciaSelecionadas
  ) {
    const inspecoes = encodeURI(inspecoesSelecionadas);
    const medicoes = encodeURI(medicoesSelecionadas);
    const origemMedicoes = encodeURI(origemMedicoesSelecionadas);
    const tensaoReferencia = encodeURI(tensaoReferenciaSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-por-municipio?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional_id=${regionalId}&inspecoes=${inspecoes}&medicoes=${medicoes}&origem_medicoes=${origemMedicoes}&tensao_referencia=${tensaoReferencia}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosIndicadoresMensais(
    competenciaDe,
    competenciaAte,
    regionalId,
    municipioSelecionado,
    inspecoesSelecionadas,
    medicoesSelecionadas,
    origemMedicoesSelecionadas,
    tensaoReferenciaSelecionadas
  ) {
    const municipio = municipioSelecionado ? encodeURI(municipioSelecionado) : "";
    const inspecoes = encodeURI(inspecoesSelecionadas);
    const medicoes = encodeURI(medicoesSelecionadas);
    const origemMedicoes = encodeURI(origemMedicoesSelecionadas);
    const tensaoReferencia = encodeURI(tensaoReferenciaSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-mensais?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional_id=${regionalId}&municipio_selecionado=${municipio}&inspecoes=${inspecoes}&medicoes=${medicoes}&origem_medicoes=${origemMedicoes}&tensao_referencia=${tensaoReferencia}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  getItemsGroupedByUcs(
    competenciaDe,
    competenciaAte,
    regionalId,
    municipioSelecionado,
    medicoesSelecionadas,
    origemMedicoesSelecionadas,
    tensaoReferenciaSelecionadas,
    localizacaoUcSelecionadas
  ) {
    const municipio = municipioSelecionado ? encodeURI(municipioSelecionado) : "";
    const medicoes = encodeURI(medicoesSelecionadas);
    const origemMedicoes = encodeURI(origemMedicoesSelecionadas);
    const tensaoReferencia = encodeURI(tensaoReferenciaSelecionadas);
    const localizacaoUc = encodeURI(localizacaoUcSelecionadas);
    
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-items-grouped-by-ucs?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional_id=${regionalId}&municipio_selecionado=${municipio}&medicoes=${medicoes}&origem_medicoes=${origemMedicoes}&tensao_referencia=${tensaoReferencia}&localizacao_uc=${localizacaoUc}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getItemsByUc(
    ucSelecionada,
    competenciaDe,
    competenciaAte
  ) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-items-by-uc?uc_selecionada=${ucSelecionada}&competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosMapaMunicipiosUcs(
    competenciaDe,
    competenciaAte,
    regionalId,
    medicoesSelecionadas,
    origemMedicoesSelecionadas,
    tensaoReferenciaSelecionadas,
    localizacaoUcSelecionadas
  ) {
    const medicoes = encodeURI(medicoesSelecionadas);
    const origemMedicoes = encodeURI(origemMedicoesSelecionadas);
    const tensaoReferencia = encodeURI(tensaoReferenciaSelecionadas);
    const localizacaoUc = encodeURI(localizacaoUcSelecionadas);
    
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-mapa-municipios-ucs?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional_id=${regionalId}&medicoes=${medicoes}&origem_medicoes=${origemMedicoes}&tensao_referencia=${tensaoReferencia}&localizacao_uc=${localizacaoUc}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getItemsGroupedByMunicipios(
    competenciaDe,
    competenciaAte,
    regionalId,
    municipioSelecionado,
    medicoesSelecionadas,
    origemMedicoesSelecionadas,
    tensaoReferenciaSelecionadas,
    localizacaoUcSelecionadas
  ) {
    const municipio = municipioSelecionado ? encodeURI(municipioSelecionado) : "";
    const medicoes = encodeURI(medicoesSelecionadas);
    const origemMedicoes = encodeURI(origemMedicoesSelecionadas);
    const tensaoReferencia = encodeURI(tensaoReferenciaSelecionadas);
    const localizacaoUc = encodeURI(localizacaoUcSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-items-grouped-by-municipios?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional_id=${regionalId}&municipio_selecionado=${municipio}&medicoes=${medicoes}&origem_medicoes=${origemMedicoes}&tensao_referencia=${tensaoReferencia}&localizacao_uc=${localizacaoUc}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getItemsByMunicipio(
    municipioSelecionado,
    competenciaDe,
    competenciaAte
  ) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-items-by-municipio?municipio_selecionado=${municipioSelecionado}&competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  getItemsRegularizacaoUcs(
    competenciaDe,
    competenciaAte,
    regionalId,
    municipioSelecionado,
    tensaoReferenciaSelecionadas,
    localizacaoUcSelecionadas,
    medicoesSelecionadas,
    origemMedicoesSelecionadas,
  ) {
    const municipio = municipioSelecionado ? encodeURI(municipioSelecionado) : "";
    const tensaoReferencia = encodeURI(tensaoReferenciaSelecionadas);
    const localizacaoUc = encodeURI(localizacaoUcSelecionadas);
    const medicoes = encodeURI(medicoesSelecionadas);
    const origemMedicoes = encodeURI(origemMedicoesSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-items-regularizacao-ucs?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional_id=${regionalId}&municipio_selecionado=${municipio}&tensao_referencia=${tensaoReferencia}&localizacao_uc=${localizacaoUc}&medicoes=${medicoes}&origem_medicoes=${origemMedicoes}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosTabelaComparativo(
    competenciaDe,
    competenciaAte,
    regionalId,
    municipioSelecionado,
    tensaoReferenciaSelecionadas,
    localizacaoUcSelecionadas,
    medicoesSelecionadas,
    origemMedicoesSelecionadas,
  ) {
    const municipio = municipioSelecionado ? encodeURI(municipioSelecionado) : "";
    const tensaoReferencia = encodeURI(tensaoReferenciaSelecionadas);
    const localizacaoUc = encodeURI(localizacaoUcSelecionadas);
    const medicoes = encodeURI(medicoesSelecionadas);
    const origemMedicoes = encodeURI(origemMedicoesSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-tabela-comparativo?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional_id=${regionalId}&municipio_selecionado=${municipio}&tensao_referencia=${tensaoReferencia}&localizacao_uc=${localizacaoUc}&medicoes=${medicoes}&origem_medicoes=${origemMedicoes}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
};
